var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "6" } }, [
            _c("h2", [
              _vm._v(_vm._s(_vm.$t("maintenance.titles.garagesView"))),
            ]),
          ]),
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "float-right",
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.openGarageModal(null)
                    },
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("maintenance.buttons.newGarage")) + " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [_c("b-col", { attrs: { sm: "12" } }, [_c("garage-table")], 1)],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }