<template>
  <!-- Add Garage Modal -->
  <b-modal
    id="modalGarage"
    size="lg"
    lazy
    no-fade
    :cancel-title="$t('maintenance.buttons.cancel')"
    :title="modal.title"
    :ok-title="modal.save_button"
    @show="handleOpen"
    @ok="saveGarage"
  >
    <b-form validate>
      <b-form-row>
        <!-- NAME -->
        <b-form-group id="fg-garage-name"
                      class="col-md-6 col-sm-12"
                      :label="$t('maintenance.labels.garageModalGarageName')"
                      label-for="garage-name"
        >
          <b-form-input 
            id="garage-name" 
            v-model.trim="garage.name"
            type="text"
            :disabled="block_ui" :state="checkField(garage.name)"
          />
        </b-form-group>

        <!-- RESELLER TRUSTED -->
        <b-form-group
          id="fg-garage-reseller-trusted"
          :label="$t('maintenance.labels.garageModalGarageTrusted')"
          label-for="garage-reseller-trusted"
          class="col-md-3 col-sm-12"
        >
          <b-form-select
            v-model="garage.reseller_trusted"
            :disabled="block_ui"
            :options="garage_reseller_trusted_options"
            :state="checkField(garage.reseller_trusted)"
          />
        </b-form-group>

        <!-- STATUS -->
        <b-form-group id="fg-garage-status" 
                      class="col-md-3 col-sm-12"
                      :label="$t('maintenance.labels.garageModalGarageStatus')"
                      label-for="garage-status"
        >
          <b-form-select v-model="garage.status" 
                         :disabled="block_ui" 
                         :options="garage_status_options"
                         :state="checkField(garage.status)"
          />
        </b-form-group>
      </b-form-row>

      <hr>

      <address-edit
        :block_ui="block_ui"
        :city="garage.address_city"
        :country="garage.address_country"
        :neighborhood="garage.address_neighborhood"
        :postal_code="garage.address_postal_code"
        :state="garage.address_state"
        :street_details="garage.address_street_details"
        :street_name="garage.address_street_name"
        :street_no_number="garage.address_street_no_number"
        :street_number="garage.address_street_number"
        :validation="btnSaveClicked"
        @update-form-data="updateAddressModel"
      />
      <hr>
      <b-form-row>
        <b-form-group 
          id="fg-garage-name" 
          class="col-md-6 col-sm-12"
          :label="$t('maintenance.labels.garageModalscheduleTypes')" 
          label-for="garage-name"
        >
          <b-form-checkbox-group
            v-model="selectedMaintenanceTypes"
            :options="scheduleTypesOptions"
            name="maintanance-types-input"
          />
        </b-form-group>
      </b-form-row>
    </b-form>
  </b-modal>
</template>

<script>
  import {
    enumStatus,
    enumResellerTrusted
  } from '@utils/garage';
  import ScheduleTypeEnum from '@graphql/schedules/enum/types';

  export default {
    name: 'ModalGarage',
    components: {
      AddressEdit: () => import('@components/address-edit'),
    },
    data() {
      return {
        emptyGarage: {
          id: '',
          name: '',
          status: '',
          reseller_trusted: null,
          address_street_name: '',
          address_street_number: '',
          address_street_no_number: false,
          address_street_details: '',
          address_neighborhood: '',
          address_city: '',
          address_state: '',
          address_country: 'BR',
          address_postal_code: '',
        },
        garage: {
          ...this.emptyGarage
        },
        modal: {
          title: this.$t('maintenance.labels.garageNewTitle'),
          save_button: this.$t('maintenance.labels.garageNewSaveButton'),
          save_title_success: this.$t('maintenance.labels.garageNewSaveSuccess'),
        },
        original_data: null,
        btnSaveClicked: false,
        action: '',
        block_ui: false,
        garage_status_options: enumStatus,
        garage_reseller_trusted_options: enumResellerTrusted,
        selectedMaintenanceTypes: [],
        scheduleTypesOptions: [
          {
            text: this.$t('maintenance.scheduleTypeEnum.CORRECTIVE'),
            value: ScheduleTypeEnum.CORRECTIVE
          },
          {
            text: this.$t('maintenance.scheduleTypeEnum.PREVENTIVE'),
            value: ScheduleTypeEnum.PREVENTIVE,
          }
        ]
      };
    },
    methods: {
      handleOpen() {
        this.block_ui = false;
        const data = this.$store.getters['garage/modalData'] || {};
        this.btnSaveClicked = false;


        if ( !data || !data.id ) {
          this.garage = this.emptyGarage;
          this.modal.title = this.$t('maintenance.labels.garageNewTitle');
          this.modal.save_button = this.$t('maintenance.labels.garageNewSaveButton');
          this.modal.save_title_success = this.$t('maintenance.labels.garageNewSaveSuccess');
          return;
        }
          
        this.garage = {
          ...data
        };
        
        this.selectedMaintenanceTypes = [];
        
        for ( let maitanance_type in data.schedule_types ) {
          if( data.schedule_types[maitanance_type ] === true ) {
            this.selectedMaintenanceTypes.push( maitanance_type.toUpperCase() );
          }
        }

        this.modal.title = this.$t('maintenance.labels.garageEditTitle');
        this.modal.save_button = this.$t('maintenance.labels.garageEditSaveButton');
        this.modal.save_title_success = this.$t('maintenance.labels.garageEditSaveSuccess');

      },
      checkField(field) {
        return this.btnSaveClicked ? !!field : null;
      },
      updateAddressModel(_item, _data) {
        this.garage['address_' + _item] = _data;
      },
      showSuccessAlert() {
        this.$root.$emit('bv::hide::modal', 'modalGarage');
        this.$swal({
          type: 'success',
          title: this.modal.save_title_success,
          showConfirmButton: false,
          timer: 2500,
        }).then(() => {
          this.$store.commit('garage/resetModalData');
          this.$emit('success');
        });
      },
      showErrorAlert(err) {
        this.$swal({
          type: 'error',
          title: 'Ocorreu um erro!',
          text: err || 'Verifique os dados informados',
          showConfirmButton: false,
          timer: 3500,
        }).then(() => {
          this.block_ui = false;
        });
      },

      async saveGarage(e) {
        e.preventDefault();
        this.btnSaveClicked = true;
        this.block_ui = true;
        const original_data = this.garage;
        this.action = 'createGarage';
        if (this.garage.id) this.action = 'updateGarage';

        this.$swal.fire({
          title: 'Salvando alterações, aguarde',
          onBeforeOpen: () => this.$swal.showLoading()
        });

        if (this.garage.address_street_no_number) this.garage.address_street_number = '';


        this.garage.schedule_types = {
          preventive: this.selectedMaintenanceTypes.includes(ScheduleTypeEnum.PREVENTIVE),
          corrective: this.selectedMaintenanceTypes.includes(ScheduleTypeEnum.CORRECTIVE)
        }

        this.$store.dispatch(`garage/${this.action}`, this.garage)
          .then(() => this.showSuccessAlert())
          .catch((err) => {
            this.garage = original_data;
            this.showErrorAlert(err);
          });
      },
    },
  };

</script>
