<template>
  <Layout>
    <b-row>
      <b-col cols="6">
        <h2>{{ $t('maintenance.titles.garagesView') }}</h2>
      </b-col>
      <b-col cols="6">
        <b-button variant="primary" class="float-right" @click="openGarageModal(null)">
          {{ $t('maintenance.buttons.newGarage') }}
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <garage-table />
      </b-col>
    </b-row>
  </Layout>
</template>

<script>
  import Layout from '@layouts/main';
  import GarageTable from '@components/garage';

  export default {
    name: 'AdminOficinas',
    components: {
      Layout,
      GarageTable,
    },
    methods: {
      openGarageModal() {
        this.$store.commit('garage/resetModalData');
        this.$root.$emit('bv::show::modal', 'modalGarage');
      }
    }
  };
</script>
