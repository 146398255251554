var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modalGarage",
        size: "lg",
        lazy: "",
        "no-fade": "",
        "cancel-title": _vm.$t("maintenance.buttons.cancel"),
        title: _vm.modal.title,
        "ok-title": _vm.modal.save_button,
      },
      on: { show: _vm.handleOpen, ok: _vm.saveGarage },
    },
    [
      _c(
        "b-form",
        { attrs: { validate: "" } },
        [
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-md-6 col-sm-12",
                  attrs: {
                    id: "fg-garage-name",
                    label: _vm.$t("maintenance.labels.garageModalGarageName"),
                    "label-for": "garage-name",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "garage-name",
                      type: "text",
                      disabled: _vm.block_ui,
                      state: _vm.checkField(_vm.garage.name),
                    },
                    model: {
                      value: _vm.garage.name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.garage,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "garage.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  staticClass: "col-md-3 col-sm-12",
                  attrs: {
                    id: "fg-garage-reseller-trusted",
                    label: _vm.$t(
                      "maintenance.labels.garageModalGarageTrusted"
                    ),
                    "label-for": "garage-reseller-trusted",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      disabled: _vm.block_ui,
                      options: _vm.garage_reseller_trusted_options,
                      state: _vm.checkField(_vm.garage.reseller_trusted),
                    },
                    model: {
                      value: _vm.garage.reseller_trusted,
                      callback: function ($$v) {
                        _vm.$set(_vm.garage, "reseller_trusted", $$v)
                      },
                      expression: "garage.reseller_trusted",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  staticClass: "col-md-3 col-sm-12",
                  attrs: {
                    id: "fg-garage-status",
                    label: _vm.$t("maintenance.labels.garageModalGarageStatus"),
                    "label-for": "garage-status",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      disabled: _vm.block_ui,
                      options: _vm.garage_status_options,
                      state: _vm.checkField(_vm.garage.status),
                    },
                    model: {
                      value: _vm.garage.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.garage, "status", $$v)
                      },
                      expression: "garage.status",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("hr"),
          _c("address-edit", {
            attrs: {
              block_ui: _vm.block_ui,
              city: _vm.garage.address_city,
              country: _vm.garage.address_country,
              neighborhood: _vm.garage.address_neighborhood,
              postal_code: _vm.garage.address_postal_code,
              state: _vm.garage.address_state,
              street_details: _vm.garage.address_street_details,
              street_name: _vm.garage.address_street_name,
              street_no_number: _vm.garage.address_street_no_number,
              street_number: _vm.garage.address_street_number,
              validation: _vm.btnSaveClicked,
            },
            on: { "update-form-data": _vm.updateAddressModel },
          }),
          _c("hr"),
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-md-6 col-sm-12",
                  attrs: {
                    id: "fg-garage-name",
                    label: _vm.$t(
                      "maintenance.labels.garageModalscheduleTypes"
                    ),
                    "label-for": "garage-name",
                  },
                },
                [
                  _c("b-form-checkbox-group", {
                    attrs: {
                      options: _vm.scheduleTypesOptions,
                      name: "maintanance-types-input",
                    },
                    model: {
                      value: _vm.selectedMaintenanceTypes,
                      callback: function ($$v) {
                        _vm.selectedMaintenanceTypes = $$v
                      },
                      expression: "selectedMaintenanceTypes",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }