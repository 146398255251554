var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("custom-data-table", {
        ref: "garageDataTable",
        attrs: {
          "query-node": "garages",
          "query-context": "Maintenance",
          fields: _vm.fields,
          filters: _vm.filters,
          query: _vm.garage_list_query,
          "is-driver": false,
        },
        on: { "vuetable:row-clicked": _vm.openGarageModal },
        scopedSlots: _vm._u(
          [
            {
              key: "name",
              fn: function (props) {
                return _c("span", {}, [
                  _vm._v(_vm._s(props.rowData.name) + " "),
                ])
              },
            },
            {
              key: "address_street_name",
              fn: function (props) {
                return _c("span", {}, [
                  _vm._v(" " + _vm._s(props.rowData.address_street_name) + " "),
                ])
              },
            },
            {
              key: "reseller_trusted",
              fn: function (props) {
                return _c("span", {}, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("format_boolean")(props.rowData.reseller_trusted)
                      ) +
                      " "
                  ),
                ])
              },
            },
            {
              key: "status",
              fn: function (props) {
                return _c(
                  "span",
                  {},
                  [
                    _c(
                      "b-badge",
                      {
                        attrs: {
                          variant: _vm._f("status_variant")(
                            props.rowData.status
                          ),
                        },
                      },
                      [_vm._v(" " + _vm._s(props.rowData.status) + " ")]
                    ),
                  ],
                  1
                )
              },
            },
            {
              key: "schedule_types",
              fn: function (props) {
                return props.rowData.schedule_types
                  ? _c("span", {}, [
                      props.rowData.schedule_types.corrective === true &&
                      props.rowData.schedule_types.preventive === true
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "maintenance.scheduleTypeEnum.CORRECTIVE"
                                  )
                                ) +
                                " / " +
                                _vm._s(
                                  _vm.$t(
                                    "maintenance.scheduleTypeEnum.PREVENTIVE"
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : props.rowData.schedule_types.corrective === true
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "maintenance.scheduleTypeEnum.CORRECTIVE"
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : props.rowData.schedule_types.preventive === true
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "maintenance.scheduleTypeEnum.PREVENTIVE"
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("maintenance.scheduleTypeEnum.NONE")
                                ) +
                                " "
                            ),
                          ]),
                    ])
                  : _vm._e()
              },
            },
            {
              key: "edit",
              fn: function (props) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { title: "Editar oficina", variant: "link" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.openGarageModal(props.rowData)
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-edit" })]
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }