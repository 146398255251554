<template>
  <div>
    <custom-data-table
      ref="garageDataTable"
      query-node="garages"
      query-context="Maintenance"
      :fields="fields"
      :filters="filters"
      :query="garage_list_query"
      :is-driver="false"
      @vuetable:row-clicked="openGarageModal"
    >
      <span slot="name" slot-scope="props">{{ props.rowData.name }} </span>
      <span slot="address_street_name" slot-scope="props">
        {{ props.rowData.address_street_name }}
      </span>
      <span slot="reseller_trusted" slot-scope="props">
        {{ props.rowData.reseller_trusted | format_boolean }}
      </span>
      <span slot="status" slot-scope="props">
        <b-badge :variant="props.rowData.status | status_variant">
          {{ props.rowData.status }}
        </b-badge>
      </span>
      <span slot="schedule_types" slot-scope="props" v-if="props.rowData.schedule_types">
        <div v-if="props.rowData.schedule_types.corrective === true && props.rowData.schedule_types.preventive ===true ">
            {{ $t('maintenance.scheduleTypeEnum.CORRECTIVE') }} / {{ $t('maintenance.scheduleTypeEnum.PREVENTIVE') }}
        </div>
        <div v-else-if="props.rowData.schedule_types.corrective === true">
          {{ $t('maintenance.scheduleTypeEnum.CORRECTIVE') }}
        </div>
        <div v-else-if="props.rowData.schedule_types.preventive === true ">
          {{ $t('maintenance.scheduleTypeEnum.PREVENTIVE') }}
        </div>
        <div v-else>
          {{ $t('maintenance.scheduleTypeEnum.NONE') }}
        </div>
      </span>
      <template slot="edit" slot-scope="props">
        <b-button
          title="Editar oficina"
          variant="link"
          @click.prevent="openGarageModal(props.rowData)"
        >
          <i class="fa fa-edit" />
        </b-button>
      </template>
    </custom-data-table>
  </div>
</template>

<script>
import { getStatus, getscheduleTypes } from '@utils/garage'
import CustomDataTable from '@components/CustomDataTable';
import GARAGE_LIST from '@graphql/garages/queries/list.gql';
import ScheduleTypeEnum from '@graphql/schedules/enum/types';

  export default {
    components: {
      CustomDataTable,
    },
    props: {
      fields: {
        type: Array,
        default: () => [],
      },
    },
    data: () => ({
      current_item: {
        id: '0'
      },
      garage_list_query: GARAGE_LIST,
      pagination: {
        current: 1,
        total: 10,
        order: 'DESC',
      },
    }),
    computed: {
      getFields() {
        return this.fields;
      },
      filters() {
        return {
          search: {
            type: 'textfield',
            props: {
              label: this.$t('maintenance.labels.garageSearch'),
              placeholder: this.$t('maintenance.labels.garageSearchPlaceholder'),
              cols: 6,
            },
            graphqlQuery(value) {
              return {
                search: value
              };
            },
          },
          reseller_trusted: {
            type: 'select',
            cols: 3,
            props: {
              label: this.$t('maintenance.labels.garageTrusted'),
              options: [{
                  value: null,
                  text: this.$t('maintenance.options.garageAll')
                },
                {
                  value: true,
                  text: this.$t('maintenance.options.garageYes')
                },
                {
                  value: false,
                  text: this.$t('maintenance.options.garageNo')
                },
              ],
            },
            graphqlQuery(value) {
              if (value === null) return;

              return {
                reseller_trusted: value
              };
            }
          },

          status: {
            type: 'select',
            cols: 3,
            props: {
              label: this.$t('maintenance.labels.garageStatus'),
              placeholder: this.$t('maintenance.labels.garageStatus'),
              options: [{
                  value: null,
                  text: this.$t('maintenance.options.garageAll')
                },
                {
                  value: 'ACTIVE',
                  text: this.$t('maintenance.options.garageActive')
                },
                {
                  value: 'INACTIVE',
                  text: this.$t('maintenance.options.garageInactive')
                },
              ],
            },
            graphqlQuery(value) {
              return {
                status: value ? getStatus(value) : getStatus
              };
            }
          },

          maintanance_type: {
            type: 'select',
            cols: 2,
            props: {
              label: this.$t('maintenance.labels.garageModalscheduleTypes'),
              placeholder: this.$t('maintenance.labels.garageModalscheduleTypes'),
              options: [{
                  value: null,
                  text: this.$t('maintenance.options.garageAll')
                },
                {
                  value: ScheduleTypeEnum.PREVENTIVE.toLowerCase(),
                  text: this.$t('maintenance.scheduleTypeEnum.PREVENTIVE')
                },
                {
                  value: ScheduleTypeEnum.CORRECTIVE.toLowerCase(),
                  text: this.$t('maintenance.scheduleTypeEnum.CORRECTIVE')
                },
                {
                  value: 'both',
                  text: this.$t('maintenance.scheduleTypeEnum.BOTH')
                },
                {
                  value: 'none',
                  text: this.$t('maintenance.scheduleTypeEnum.NONE')
                },
              ],
            },
            graphqlQuery(value) {
              if (value === null) return;

              
              return {
                schedule_types: getscheduleTypes(value)

              };
            }
          }
        };
      },
    },
    methods: {
      openGarageModal(_item = null) {
        if (_item && _item.data)
          this.$emit('open-modal', _item);
      },
      refreshData() {
        this.$refs.garageDataTable.$_refresh();
      }
    },
  };

</script>
