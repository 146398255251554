var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("garage-list-table", {
        ref: "garageListTable",
        attrs: { fields: _vm.fieldsToShow },
        on: { "open-modal": (garageData) => _vm.openModal(garageData) },
      }),
      _c("modal-garage", {
        on: { success: () => this.$refs.garageListTable.refreshData() },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }