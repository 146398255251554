<template>
  <div>
    <garage-list-table
      ref="garageListTable"
      :fields="fieldsToShow"
      @open-modal="garageData => openModal(garageData)"
    />
    <modal-garage @success="() => this.$refs.garageListTable.refreshData()" />
  </div>
</template>

<script>
  import GarageListTable from '@components/garage/garage-list-table';
  import ModalGarage from '@components/modals/garage';


  export default {
    name: 'GarageTable',
    components: {
      GarageListTable,
      ModalGarage
    },
    data() {
      return {
        fieldsToShow: [
          {
            name: 'name',
            title: this.$t('maintenance.tables.fieldsToShow.name'),
          },
          {
            name: 'address_street_name',
            title: this.$t('maintenance.tables.fieldsToShow.address_street_name'),
          },
          {
            name: 'reseller_trusted',
            title: this.$t('maintenance.tables.fieldsToShow.reseller_trusted'),
          },
          {
            name: 'schedule_types',
            title: this.$t('maintenance.labels.garageModalscheduleTypes'),
          },
          {
            name: 'status',
            title: this.$t('maintenance.tables.fieldsToShow.status'),
          },
          {
            name: 'edit',
            title: this.$t('maintenance.tables.fieldsToShow.edit'),
          }
        ]
      };
    },
    methods: {
      openModal({data}) {
        this.$store.commit('garage/setModalData', data);
        this.$root.$emit('bv::show::modal', 'modalGarage');
      }
    }
  };
</script>
