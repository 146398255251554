import { i18next } from '../translate/i18n';

export const enumStatus = [
  {
    text: i18next.t('maintenance.enums.enumStatusActive'),
    value: 'ACTIVE'
  },
  { 
    text: i18next.t('maintenance.enums.enumStatusInactive'),
    value: 'INACTIVE'
  }
];

export const getStatus = (status) => {
  switch (status) {
    case 'ACTIVE':
      return 'ACTIVE'
    case 'INACTIVE':
      return 'INACTIVE'
    default:
      return ['ACTIVE', 'INACTIVE']
  }
};

export const getscheduleTypes = (value) => {
  switch(value) {
    case 'corrective':
      return {
        corrective: true
      };
    case 'preventive':
      return {
        preventive: true
      };
    case 'both':
      return {
        corrective: true,
        preventive: true
      };
    case 'none':
      return {
        corrective: false,
        preventive: false
      };
    default:
      return null
  }
}

export const enumResellerTrusted = [
  { text: i18next.t('maintenance.enums.enumResellerTrustedYes'), value: true },
  { text: i18next.t('maintenance.enums.enumResellerTrustedNo'), value: false },
];

